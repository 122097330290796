<template>
  <div>
    <nav class="navbar-custom-menu">
      <div class="navbar-item">
        <router-link
          :to="{ name: 'Dashboard'}"
          class="navbar-link icon-text is-arrowless"
        >
          <span class="icon is-small">
            <i class="fas fa-chevron-left"></i>
          </span>
          <span>Passport</span>
        </router-link>
      </div>
    </nav>
    <hr />

    <div class="contentWrapper">
      <p
        class="title is-3"
        v-text="$t('Views.Proposals.Proposals.Main_Title')"
      ></p>
      <transition
        name="fade"
        mode="out-in"
      >
        <ui-loader
          v-if="isLoading"
          key="loading"
        />
        <div
          v-if="!isLoading"
          key="proposals"
        >
          <Message
            v-if="!proposals.length"
            class="is-info"
          >
            {{ $t('Views.Proposals.Proposals.Message_NoProposals') }}
          </Message>
          <div class="columns is-multiline">
            <div
              v-for="(proposal, index) in proposals"
              :key="index"
              class="column is-one-quarter"
            >
              <ProposalListItem :proposal="proposal" />
            </div>
          </div>
          <div>&nbsp;</div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import proposalProvider from '@/providers/proposal'
import ProposalListItem from '../../components/Proposals/proposalListItem.vue'
import Message from '../../components/UI/Message.vue'

export default {
  components: { ProposalListItem, Message },

  data() {
    return {
      proposals: [],
      isLoading: true
    }
  },

  computed: {},

  created() {
    this.getProfileProposals()
  },

  methods: {
    getProfileProposals() {
      proposalProvider.methods.getProfileProposals().then((response) => {
        if (response.status === 200) {
          this.proposals = response.data.Result
        }
      })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style></style>
