<template>
  <div class="box p-0">
    <figure class="image is-4by3">
      <img
        src="@/assets/img/spacer1_1.gif"
        :style="{backgroundImage: `url(${locationImage})`}"
        class="has-background-grey-light"
        alt="Placeholder image"
      />
    </figure>
    <div class="p-3">
      <div class="media">
        <div
          v-if="locationLogo"
          class="media-left"
        >
          <figure class="image is-48x48">
            <img
              src="@/assets/img/spacer1_1.gif"
              :style="{backgroundImage: `url(${locationLogo})`}"
              class="has-background-grey-light"
              alt="Placeholder image"
            />
          </figure>
        </div>
        <div class="media-content">
          <p class="title is-6">{{ proposal.Location.Name }}</p>
          <p class="subtitle is-6">
            {{ proposalDate }}<br />
            {{ proposal.StartMinutes | minutesToTime }} -
            {{ proposal.EndMinutes | minutesToTime }}
          </p>
        </div>
      </div>
      <router-link
        :to="{name: 'proposal-detail', params: { proposalKey: proposal.Key } }"
        class="card-footer-item"
      ><span>View proposal</span></router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    proposal: {
      type: Object,
      required: true
    }
  },

  computed: {
    proposalDate() {
      return this.$options.filters.longDateFormat(
        this.$options.filters.parseIsoDateStringToDate(this.proposal.StartDate)
      )
    },
    locationImage() {
      return this.$options.filters.getLocationImage(this.proposal.Location.Image, this.proposal.Location.Id, '640')
    },
    locationLogo() {
      return this.proposal.Location.Logo ? this.$options.filters.getLocationImage(this.proposal.Location.Logo, this.proposal.Location.Id, '84') : ''
    }
  },
}
</script>

<style lang="scss" scoped>
</style>